import React, { Fragment, useState } from "react";
import { DropdownMenu, Nav, NavItem, Button } from "reactstrap";

import UserImage from "../UserImage";
import UserInfo from "../UserInfo";
import DarkLightToggler from "../../components/DarkLightToggler";
import { logoutRedirect } from "../../data/gateway";
import avtar from "../../styles/utils/images/avtar.png";
import { IUser } from "../../data/services/user.types";
import UserPreferences from "./UserPreferences";
import { useFeedbackContext } from "../../contexts/FeedbackContext";
import { useLocation } from "react-router-dom";
// import FeedbackApp from "./FeedbackApp";

const LogoutButton: React.FC = () => (
    <Button
        className="btn-pill btn-shadow btn-shine"
        color="focus"
        onClick={() => {
            logoutRedirect();
            return false;
        }}
    >
        Logout
    </Button>
);

type UserActionsDropdownMenuProps = {
    account: IUser;
};

const UserActionsDropdownMenu: React.FC<UserActionsDropdownMenuProps> = ({
    // eslint-disable-next-line react/prop-types
    account,
}) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const { pathname } = useLocation();
    const toggleOpen = () => {
        setOpen((prevState) => !prevState);
    };
    const { openFeedback, setCurrentModuleLocation } = useFeedbackContext();

    // const [isFeedbackFormOpen, setFeedbackFormOpen] = useState<boolean>(false);
    const toggleFeedbackFormOpen = () => {
        // setFeedbackFormOpen((prevState) => !prevState);
        // setFeedbackFormOpen(true);
        setCurrentModuleLocation(pathname);
        openFeedback();
    };

    return (
        <Fragment>
            <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-plum-plate">
                        <div className="menu-header-image opacity-2" />
                        <div className="menu-header-content text-left">
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <UserImage src={avtar} />
                                    </div>
                                    <UserInfo account={account} />
                                    <div className="widget-content-right mr-2">
                                        <LogoutButton />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {/* <h5 className="app-sidebar__heading">
                                        Contact Us
                                    </h5> */}
                                    <div
                                        className="widget-content-left app-sidebar__heading cursor-pointer mr-3"
                                        onClick={() => toggleFeedbackFormOpen()}
                                    >
                                        Feedback
                                    </div>
                                    <DarkLightToggler />
                                </div>
                                <div className="user-contact-us-menu">
                                    <a
                                        href="https://meetings.netlifesaas.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="metismenu-icon pe-7s-date " />{" "}
                                        Schedule a Call
                                    </a>
                                    {/* <a
                        href="https://erp.netlifesaas.com/helpdesk"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="metismenu-icon pe-7s-help1" />{" "}
                        Customer Support Desk
                    </a> */}
                                    <div
                                        className="mr-2 cursor-pointer flex items-center justify-center p-2 rounded hover:bg-gray-200 transition duration-200"
                                        onClick={() => toggleOpen()}
                                    >
                                        <i className="fa fa-user-cog text-lg text-gray-600" />
                                    </div>
                                </div>
                                {/* <div className='user-contact-us-menu'>
      <a
        href="https://gsmsystems.atlassian.net/servicedesk/customer/portal/1/group/9/create/30"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="metismenu-icon pe-7s-help1" />{" "}
        Quick Request
      </a>
      <a
        href="https://gsmsystems.atlassian.net/wiki/spaces/GSMHD/overview"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="metismenu-icon pe-7s-help1" />{" "}
        Netlife Wiki
      </a>
    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Nav vertical>
                    <NavItem className="nav-item-btn text-center">
                        <Button
                            size="sm"
                            className="btn-wide"
                            color="primary"
                            disabled
                        >
                            Open Messages
                        </Button>
                    </NavItem>
                </Nav>
            </DropdownMenu>
            <UserPreferences
                isOpen={isOpen}
                setOpen={setOpen}
                initialData={account}
            />
            {/* <FeedbackApp isOpen={isFeedbackFormOpen} /> */}
        </Fragment>
    );
};

export default UserActionsDropdownMenu;
